// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
    query Search(
      $query: String!,
      $page: Int,
      $first: Int
      ) {
    list: search(
      query: $query,
      page: $page,
      first: $first
    ) {
      data {
        data
      }
      dataGridInfo {
        columns {
          name
          label
        }
        metadata
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export default QUERY;
